














































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ContributionDialogViewModel from
  '@/vue-app/view-models/components/goals-dashboard/contribute-goals/contribution-dialog-view-model';

@Component({
  name: 'WealthContributionDialog',
  components: {
    FlexibleDeposit: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDeposit.vue'),
    GoalDistribution: () => import('@/vue-app/components/goals-dashboard/contribute-goals/wealth/GoalDistribution.vue'),
    FlexibleDepositConfirmation: () => import('@/vue-app/components/allianz-dashboard/deposits/flexible_deposits/FlexibleDepositConfirmation.vue'),
    GoalContributionSuccess: () => import('@/vue-app/components/goals-dashboard/contribute-goals/wealth/GoalContributionSuccess.vue'),
    AttachYourProof: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/AttachYourProof.vue'),
    SpecialDepositInformation: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/SpecialDepositInformation.vue'),
    SpecialDepositSuccess: () => import('@/vue-app/components/allianz-dashboard/deposits/special_deposits/SpecialDepositSuccess.vue'),
  },
})
export default class WealthContributionDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  contribution_dialog_modal_model = Vue.observable(
    new ContributionDialogViewModel(this),
  );

  endProcess() {
    this.contribution_dialog_modal_model.resetProperties();
  }

  created() {
    this.contribution_dialog_modal_model.initialize();
  }
}
