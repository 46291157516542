import Vue from 'vue';
import TYPES from '@/types';

// Application
import LinkedGoalStateManagerService
  from '@/modules/flagship/investor-goal/linked-investor-goal/application/services/linked-goal-state-manager-service';
import InvestorGoalScheduledDistributionWealthFlexibleCommand
  from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/application/commands/investor-goal-scheduled-distribution-wealth-flexible-command';

// Domain
import {
  WealthFlexibleDepositStateManager,
} from '@/modules/my-investment/allianz/transaction/domain/state/wealth-flexible-deposit-state-manager';
import LinkedGoalState
  from '@/modules/flagship/investor-goal/linked-investor-goal/domain/state/linked-goal-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContributionDialogViewModel {
  @Inject(TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE)
  private readonly linked_goal_state_manager_service!:
    LinkedGoalStateManagerService;

  @Inject(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
  private readonly wealth_flexible_deposit_state_manager!:
    WealthFlexibleDepositStateManager;

  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_COMMAND)
  private readonly investor_goal_scheduled_distribution_wealth_flexible_command!: (
    InvestorGoalScheduledDistributionWealthFlexibleCommand);

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.flexible_deposit_modal';

  readonly view: Vue;

  is_loading = false;

  current_step = 1;

  investment_product_fund_type_id = '';

  readonly total_steps = 4;

  successful_result = false;

  linked_goal_state: LinkedGoalState;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('steps_with_balance');

  steps = [
    {
      component: 'FlexibleDeposit',
      visible: true,
      step_number: 1,
    },
    {
      component: 'GoalDistribution',
      visible: true,
      step_number: 2,
    },
    {
      component: 'FlexibleDepositConfirmation',
      visible: true,
      step_number: 3,
    },
    {
      component: 'GoalContributionSuccess',
      visible: true,
      step_number: 4,
    },
  ];

  special_deposit_steps = [
    {
      header: this.translate('steps_special_deposit.0'),
      component: 'SpecialDepositInformation',
      visible: true,
      step_number: 5,
    },
    {
      header: this.translate('steps_special_deposit.1'),
      component: 'AttachYourProof',
      visible: true,
      step_number: 6,
    },
    {
      header: this.translate('steps_special_deposit.2'),
      component: 'GoalDistribution',
      visible: true,
      step_number: 7,
    },
    {
      header: '',
      component: 'SpecialDepositSuccess',
      visible: false,
      step_number: 8,
    },
  ];

  public constructor(view: Vue, linked_goal_state?: LinkedGoalState) {
    this.view = view;
    this.linked_goal_state = linked_goal_state || this.linked_goal_state_manager_service
      .getLinkedGoalState();
  }

  initialize = async () => {
    this.setCorrectMenuSteps();
  }

  get show_stepper_header() {
    return this.current_step < 4 || this.show_special_deposit_steps;
  }

  get show_flexible_deposit_steps() {
    return this.current_step < 4;
  }

  get show_special_deposit_steps() {
    return this.current_step >= 5 && this.current_step <= 6;
  }

  get steps_special_deposit() {
    return this.special_deposit_steps.filter((step) => step.header !== '' && step.visible);
  }

  get mount_special_deposit_flow() {
    return this.current_step >= 5 && this.current_step <= 8;
  }

  getOpacityClassToSteps = (current_step_number: number) => ((this
    .current_step === current_step_number) ? '' : 'opacity-header-step');

  nextStep = async () => {
    if (this.current_step >= 5) {
      if (this.current_step === 7) {
        const saved = await this.saveDistributedAmountLinkedGoals();
        if (saved) {
          this.current_step += 1;
        }
      } else {
        this.current_step += 1;
      }
    } else if (this.current_step < this.total_steps) {
      this.current_step += 1;
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
    }
  }

  restoreState = () => {
    this.wealth_flexible_deposit_state_manager.restore();
  }

  resetProperties = () => {
    this.restoreState();
    this.current_step = 1;
    this.view.$emit('endProcess');
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  specialContributionSelected = () => {
    this.current_step = 5;
  }

  setCorrectMenuSteps = () => {
    this.menu_steps = this.translate('steps_with_balance');
  }

  saveDistributedAmountLinkedGoals = async (): Promise<boolean> => {
    try {
      await this.investor_goal_scheduled_distribution_wealth_flexible_command.execute(
        this.wealth_flexible_deposit_state_manager.state.goal_distribution,
      );
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.investor_goal_scheduled_distribution_wealth_flexible_command'));
      return false;
    }
  }
}
